import { graphql } from "gatsby"
import React from "react"
import BackgroundImages from "../components/BackgroundImages";
import GlobalStyles from "../styles/GlobalStyles";
 

export default function HomePage({data}) {
  const posts = data.posts.nodes;
  return (
    <div className="page-wrapper">
      <GlobalStyles/>
      <h1>VisualPause</h1>
      <BackgroundImages posts={posts}/>
    </div>
  );
}

// Query for posts.
export const query = graphql`
  query PostsQuery{
    posts: allSanityPost {
      nodes {
        title
        image {
          asset {
            fluid(maxWidth: 2000) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }`;
