import React from "react";
import Img from "gatsby-image";

export default function BackgroundImages({posts}) {
  let postCount = posts.length;
  let key = Math.floor(Math.random() * postCount);
  return (
    <div className="background-image--outer">
      <Img className="background-image--inner" objectFit='fill' alt={posts[key].title} fluid={posts[key].image.asset.fluid}/>
    </div>
  );

}