import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  .page-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  h1 {
    color: #fff;
    position: fixed;
    bottom: 5%;
    right: 5%;
    z-index: 2;
    font-family: sans-serif;
  }

  .background-image--inner {
    height: 100vh;
  }
`;

export default GlobalStyles;